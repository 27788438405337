<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-sheet
            color="transparent"
            elevation="6"
              rounded="lg"
            >
            <v-sparkline
              :labels="labels"
              :value="value"
              :gradient="['#0D47A1', '#00BCD4', '#CDDC39']"
              color="white"
              line-width="3"
              height="50"
              padding="15"
              type="bar"
              auto-draw
              :auto-draw-duration="750"
            ></v-sparkline>
          </v-sheet>
          <v-card-text class="pt-1">
            <div class="text-h6 font-weight-light mb-2">Alert Volume</div>
            <v-data-table :headers="headers" :items="alerts"></v-data-table>
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small> mdi-clock </v-icon>
            <span class="text-caption grey--text font-weight-light">last alert 10min ago</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"

export default {
  name: "SourceAlertsTab",

  components: {},

  computed: {
    ...mapFields("source", ["selected.alerts", "selected.loading"]),
  },

  data() {
    return {
      labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
      value: [200, 675, 410, 390, 310, 460, 250, 240],
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Description", value: "description" },
        { text: "Source", value: "originator" },
      ],
    }
  },
}
</script>
