<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left">
      <template v-slot:activator="{ on }">
        <v-chip pill small v-on="on">
          <v-avatar color="error" left>
            <span class="white--text">{{ value.name | initials }}</span>
          </v-avatar>
          {{ value.name }}
        </v-chip>
      </template>
      <v-card width="400">
        <v-list dark>
          <v-list-item>
            <v-list-item-avatar color="error">
              <span class="white--text">{{ value.name | initials }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ value.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ value.status }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                :to="{
                  name: 'IncidentTableEdit',
                  params: { name: value.name },
                }"
              >
                <v-icon>mdi-arrow-right-bold-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-list>
          <template
            v-if="value.commander && value.commander.individual && value.commander.individual.email"
          >
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-account-tie</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>{{ value.commander.individual.email }}</v-list-item-subtitle>
            </v-list-item>
          </template>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-format-title</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle>{{ value.title }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle>{{ value.description }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-if="value.ai_resolution_summary">
            <v-list-item-action>
              <v-icon>mdi-creation</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle>
              <ai-resolution-summary-dialog :summary="value.ai_resolution_summary" />
            </v-list-item-subtitle>
          </v-list-item>
          <template v-if="value.incident_priority && value.incident_priority.name">
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-flag-outline</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>
                <incident-priority
                  :priorityName="value.incident_priority.name"
                  :priorityColor="value.incident_priority.color"
                />
              </v-list-item-subtitle>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import AiResolutionSummaryDialog from '@/incident/AiResolutionSummaryDialog.vue'
import IncidentPriority from '@/incident/priority/IncidentPriority.vue'


export default {
  name: "IncidentPopover",

  components: {
    AiResolutionSummaryDialog,
    IncidentPriority,
  },

  data: () => ({
    menu: false,
  }),

  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
}
</script>
