<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <v-row no-gutters class="mb-2">
      <v-col>
        <div class="headline">
          <v-icon>mdi-database-import-outline</v-icon>
          Observability Data Sources
        </div>
      </v-col>
      <v-col class="text-right">
        <table-filter-dialog :projects="defaultUserProjects" />
        <v-btn small color="info" class="ml-2" @click="createEditShow()">
          <v-icon small>mdi-plus</v-icon>
          New Data Source
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="space-between" no-gutters>
      <delete-dialog />
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.name">
              <v-card class="ma-2" outlined>
                <!-- Card Title with link -->
                <v-card-title class="d-flex justify-space-between align-center">
                  <router-link :to="{ name: 'SourceDetail', params: { name: item.name, tab: 'details' } }">
                    <v-icon large color="blue" left>mdi-database-import-outline</v-icon>
                    <span class="title">{{ item.name }}</span>
                  </router-link>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        label
                        class="mr-2"
                        v-on="on"
                        @click="openIncidentTab"
                      >
                        <v-icon
                          small
                          left
                          >
                          mdi-domain
                        </v-icon>
                          {{ item.incidents.length }}
                        </v-chip>
                    </template>
                    <span>Number of times used in a IAO. Click to view details.</span>
                    <v-dialog v-model="incidentDialog" max-width="1080">
                      <incident-tab :inputIncidents="item.incidents" />
                    </v-dialog>
                  </v-tooltip>
                </v-card-title>

                <!-- Card Subtitle for Project Name -->
                <v-card-subtitle class="mt-2">
                  <project-card v-if="item.project" :project="item.project" />
                </v-card-subtitle>

                <!-- Card Text for Additional Information -->
                <v-card-text>
                  <v-list dense>
                    <v-list-item v-if="item.owner">
                      <v-list-item-icon>
                        <v-icon color="error">mdi-account-circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>Owner: <service-popover :service="item.owner" /></v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="cyan">mdi-clock-time-four-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>Last Loaded: {{ item.data_last_loaded_at | formatRelativeDate }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="error">mdi-currency-usd</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>Cost: ${{ item.cost }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="success">mdi-finance</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>ROI: {{ item.roi }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="blue-grey">mdi-domain</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>Used in <span class="big-number">{{ item.incidents.length }}</span> IAOs</v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="item.source_status">
                      <v-list-item-icon>
                        <v-icon color="success">mdi-check-circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>Status: {{ item.source_status.name }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="item.source_data_format">
                      <v-list-item-icon>
                        <v-icon color="teal">mdi-format-color-text</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>Data Format: {{ item.source_data_format.name }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="item.source_type">
                      <v-list-item-icon>
                        <v-icon color="warning">mdi-transfer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>Source Type: {{ item.source_type.name }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="item.source_transport">
                      <v-list-item-icon>
                        <v-icon color="purple">mdi-ship-wheel</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>Transport: {{ item.source_transport.name }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="item.source_environment">
                      <v-list-item-icon>
                        <v-icon color="warning">mdi-earth</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>Environment: {{ item.source_environment.name }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="secondary">mdi-axis-arrow-info</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>{{ item.description }}</v-list-item-content>
                    </v-list-item>

                  </v-list>
                </v-card-text>

                <!-- Card Actions for Editing and Deleting -->
                <v-card-actions class="justify-end">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="createEditShow(item)">
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="removeShow(item)">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import DeleteDialog from "@/data/source/DeleteDialog.vue"
import NewEditSheet from "@/data/source/NewEditSheet.vue"
import RouterUtils from "@/router/utils"
import ServicePopover from "@/service/ServicePopover.vue"
import TableFilterDialog from "@/data/source/TableFilterDialog.vue"
import ProjectCard from "@/project/ProjectCard.vue"
import IncidentTab from "@/incident/IncidentTab.vue"

export default {
  name: "SourceTable",

  components: {
    DeleteDialog,
    NewEditSheet,
    ServicePopover,
    TableFilterDialog,
    ProjectCard,
    IncidentTab,
  },

  data() {
    return {
      headers: [],
      incidentDialog: false,
    }
  },

  computed: {
    ...mapFields("source", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters",
      "table.options.filters.tag",
      "table.options.filters.tag_type",
      "table.options.filters.project",
      "table.options.filters.source_environment",
      "table.options.filters.source_type",
      "table.options.filters.source_transport",
      "table.options.filters.source_data_format",
      "table.options.filters.source_status",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("auth", ["currentUser.projects"]),

    defaultUserProjects: {
      get() {
        let d = null
        if (this.projects) {
          let d = this.projects.filter((v) => v.default === true)
          return d.map((v) => v.project)
        }
        return d
      },
    },
  },

  methods: {
    ...mapActions("source", ["getAll", "createEditShow", "removeShow"]),
    async openIncidentTab() {
      this.incidentDialog = true
      this.$nextTick(() => {
        this.$refs.incidentTab = this.incidents
      })
    },
  },

  created() {
    this.filters = {
      ...this.filters,
      ...RouterUtils.deserializeFilters(this.query),
      project: this.defaultUserProjects,
    }

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [
        vm.q,
        vm.itemsPerPage,
        vm.sortBy,
        vm.descending,
        vm.project,
        vm.source_data_format,
        vm.source_environment,
        vm.source_status,
        vm.source_transport,
        vm.source_type,
        vm.tag,
        vm.tag_type,
      ],
      () => {
        this.page = 1
        RouterUtils.updateURLFilters(this.filters)
        this.getAll()
      }
    )
  },
}
</script>


<style scoped>
.big-number {
  font-size: 24px;
  font-weight: bold;
}
</style>
